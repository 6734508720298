// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import router from "@/router";

export default function useBuildErrorAnalysisApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refBuildErrorAnalysisListTable = ref(null)
    const perPage = ref(localStorage.getItem('buildErrorAnalysisPerPage') ?? 10)
    const totalBuildErrorAnalyses = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isLoading = ref(true)
    const buildErrorAnalysisData = ref(null)

    const tableColumns = [
        {key: 'id', sortable: true},
        {key: 'name', sortable: true},
        {key: 'created_at', sortable: true},
        {key: 'actions'},
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refBuildErrorAnalysisListTable.value ? refBuildErrorAnalysisListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBuildErrorAnalyses.value,
        }
    })

    const refetchData = () => {
        localStorage.setItem('buildErrorAnalysisPerPage', perPage.value)
        refBuildErrorAnalysisListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })


    // ------------------------------------------------
    // Get Build Error Analysis List
    // ------------------------------------------------
    const fetchBuildErrorAnalyses = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store
            .dispatch('buildErrorAnalysisStore/fetchBuildErrorAnalyses', {
                sort: sort,
                page: currentPage.value,
                perPage: perPage.value,
                q: searchQuery.value,
                pagination: true
            })
            .then(response => {
                const {data, total} = response.data
                callback(data)
                totalBuildErrorAnalyses.value = response.data.pagination.total
            })
            .catch(error => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchBuildErrorAnalysis = (ctx,callback)=>{

        store
            .dispatch('buildErrorAnalysisStore/fetchBuildErrorAnalysis',  { id: router.currentRoute.params.buildErrorAnalysis })
            .then(response => {
                const {data} = response.data
                buildErrorAnalysisData.value = data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text:'Oops! Something went wrong!',
                    },
                })
                router.push('/buildErrorAnalysis/list');
            })
    }


    return {
        fetchBuildErrorAnalyses,
        fetchBuildErrorAnalysis,
        buildErrorAnalysisData,
        tableColumns,
        perPage,
        currentPage,
        totalBuildErrorAnalyses,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBuildErrorAnalysisListTable,
        isLoading,
        refetchData,
    }
}
