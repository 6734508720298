<template>

    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>

                    <b-col cols="12" md="7" mx="11"
                           class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions"
                                  :clearable="false" class="per-page-selector d-inline-block mx-50 ml-1"/>
                    </b-col>
                    <b-col cols="12" md="5" mx="1">
                        <b-row class="align-items-center">
                            <b-col cols="12" md="6" class="mb-md-0 mb-2">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                              placeholder="Search..."/>
                            </b-col>
                            <b-col class="mb-md-0 mb-2" cols="12" md="6"
                                   v-if="$can('read', 'buildErrorAnalysisCreate')">
                                <b-button class="w-100" variant="primary"
                                          @click="$router.push('/buildErrorAnalysis/create')">
                                    <span>Create Error Analysis</span>
                                </b-button>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refBuildErrorAnalysisListTable" class="position-relative" :items="fetchBuildErrorAnalyses"
                     responsive
                     :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                     :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
                     empty-text="No matching records found"
                     :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- Column: id -->
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
                </template>

                <!-- Column: Created at -->
                <template #cell(created_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
                </template>

                <!--Column: Actions -->
                <template #cell(actions)="data">

                    <b-link class="mr-1"
                            v-if="($can('read', 'buildErrorAnalysisEdit'))"
                            :to="{ name: 'buildErrorAnalysis-edit', params: { buildErrorAnalysis: data.item.id } }"
                            v-b-tooltip.hover.top="'Edit Build Error Analysis'">
                        <feather-icon icon="EditIcon" size="18"/>
                    </b-link>

                    <b-link class="mr-1"
                            v-if="($can('read', 'buildErrorAnalysisDelete'))"
                            v-b-tooltip.hover.top="'Delete Build Error Analysis'"
                            v-on:click="confirmDelete(data.item.id)">

                        <feather-icon icon="TrashIcon" size="18"/>
                    </b-link>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalBuildErrorAnalyses" :per-page="perPage"
                                      first-number
                                      last-number
                                      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useBuildErrorAnalysisApi from '@/composables/useBuildErrorAnalysisApi'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ref} from '@vue/composition-api'
import vSelect from "vue-select";

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalDate: null,
            conceptStatus: null,
        }
    },
    setup() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const {
            fetchBuildErrorAnalyses,
            tableColumns,
            perPage,
            currentPage,
            totalBuildErrorAnalyses,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildErrorAnalysisListTable,
            refetchData,
        } = useBuildErrorAnalysisApi()

        return {
            fetchBuildErrorAnalyses,
            tableColumns,
            perPage,
            currentPage,
            totalBuildErrorAnalyses,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildErrorAnalysisListTable,
            refetchData,
            userData,
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        confirmDelete(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the error build?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('buildErrorAnalysisStore/deleteBuildErrorAnalysis', {
                        id: id
                    }).then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully deleted!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    }).then(() => {
                        this.refetchData()
                    })
                }
            })
        },
    }
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
